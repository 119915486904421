/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { ContestantsContext } from "../../../App";
import { getDetailImage, useContestantDetail } from "../../../store";
import Button from "../../UI/Button";
import Modal from "react-modal";
import { VoteMomoModal } from "./modal/VoteMomoModal";
import PriceModal from "./modal/PriceModal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "60%",
    minWidth: "350px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.45)",
    zIndex: "1",
  },
};
const customStylesPriceModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "60%",
    minWidth: "350px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "575px",
    background: "#411644",
    border: "unset",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.45)",
    zIndex: "1",
  },
};

// const comboArr = [1, 10, 30, 50, 100];
const comboArr = [
  { display: "Silver Star", value: 1 },
  { display: "Gold Star", value: 2 },
  { display: "Pearl Crown", value: 3 },
  { display: "Platinum Crown", value: 4 },
  { display: "Diamond Crown", value: 5 },
];

const ContestantDetail = () => {
  const [payUrl, setPayUrl] = useState("");
  const [combo, setCombo] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [isOpenPrice, setOpenPrice] = useState(false);

  const { missId } = useParams();
  const { time, contestant, getContestantDetail, getPayUrl } =
    useContestantDetail();

  const isVoteTime =
    time.now >= time.start && time.now <= time.end ? true : false;

  const getInfo = (key) => contestant.info && contestant.info[key];
  const { contestants } = useContext(ContestantsContext);
  const missInfo = contestants.find(
    (con) => con.contestantId === contestant.contestantId
  );

  useEffect(() => {
    // getVoteList(missId, 1, 10);
    getContestantDetail(missId);
  }, [getContestantDetail, missId]);

  const onHandleVote = async (combo, gateway) => {
    try {
      // const res = await axios.get(`${fakeServer}/api/momo/${missId}/${combo}`);
      const res = await getPayUrl(gateway, missId, combo);
      setPayUrl(res.data.payUrl);
      setCombo(combo);
      setOpen(true);
    } catch (error) {
      console.log("error when get pay Url");

      setPayUrl("");
      setCombo(combo);
      setOpen(true);
    }
  };
  return (
    <div className="bs-row row-sm-15">
      <div className="bs-col md-55-15">
        <div className="detail-left">
          <img
            src={getDetailImage(contestant.code)}
            alt=""
            style={{ width: "100%" }}
          />
          {/* <div className="slide-control">
            <div
              className="detail__btn next__btn"
              type="button"
              onClick={this.previous}
            />
            <div
              className="detail__btn prev__btn"
              type="button"
              onClick={this.next}
            />
          </div>

          <Slider
            ref={(c) => (this.slider = c)}
            {...DETAIL_IMG_SLIDE_SLICK}
            className="slide-detailImages"
          >
            <div className="slide-item">
              <div className="item">
                <div className="img">
                  <div className="ImagesFrame">
                    <div className="ImagesFrameCrop0">
                      <img src={getDetailImage(missId)} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          <div className="slide-item">
            <div className="item">
              <div className="img">
                <div className="ImagesFrame">
                  <div className="ImagesFrameCrop0">
                    <img src={getDetailImage(missId)} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          </Slider> */}
        </div>
      </div>
      <div className="bs-col md-45-15">
        <div className="detail-right">
          <div className="slide-detail">
            <div className="slide-item">
              <div className="item">
                <div className="text">
                  <div className="text-index">
                    <p className="index__desc">
                      <span className="title">
                        <FormattedMessage id="name" />:{" "}
                      </span>
                      &nbsp;{contestant.fullname}
                    </p>
                    <p className="index__desc">
                      <span className="title">
                        <FormattedMessage id="contestant.id" />:
                      </span>
                      &nbsp;{contestant.code}
                    </p>
                    <p className="index__desc">
                      <span className="title">
                        {/* <FormattedMessage id="contestant.desc" /> :{" "} */}
                        Quê quán:
                      </span>
                      &nbsp;{getInfo("home")}
                    </p>{" "}
                    <p className="index__desc">
                      <span className="title">Chiều cao:</span>
                      &nbsp;{getInfo("height")}
                    </p>
                    <p className="index__desc">
                      <span className="title">Số đo ba vòng:</span>
                      &nbsp;{getInfo("bwh")}
                    </p>
                    <p className="index__desc">
                      <span className="title">
                        <FormattedMessage id="votePoints" /> :{" "}
                      </span>
                      {missInfo?.totalPoint}
                    </p>
                  </div>
                  <Button
                    className="vote_dropdown open-modal"
                    onClick={() => setOpenPrice(true)}
                    style={{ margin: "0 auto 10px" }}
                    outline
                  >
                    Xem quy đổi điểm bình chọn
                  </Button>

                  {isVoteTime && (
                    <Button
                      className="vote_dropdown"
                      style={{ margin: "0 auto" }}
                    >
                      Bình chọn qua Momo
                      <ul className={`dropdown`}>
                        {comboArr.map((combo, ind) => (
                          <li
                            key={ind}
                            style={{ fontSize: "13px", padding: "5px 8px" }}
                            onClick={() => {
                              onHandleVote(combo.value, "momo");
                            }}
                          >
                            {/* {combo} vote{combo > 1 ? "s" : ""} */}
                            {combo.display}
                          </li>
                        ))}
                      </ul>
                    </Button>
                  )}

                  {isVoteTime && (
                    <Button
                      className="vote_dropdown"
                      style={{ margin: "0 auto", marginTop: "10px" }}
                    >
                      Bình chọn qua Ngân hàng, thẻ...
                      <ul className={`dropdown`}>
                        {comboArr.map((combo, ind) => (
                          <li
                            key={ind}
                            style={{ fontSize: "13px", padding: "5px 8px" }}
                            onClick={() => {
                              onHandleVote(combo.value, "appota");
                            }}
                          >
                            {/* {combo} vote{combo > 1 ? "s" : ""} */}
                            {combo.display}
                          </li>
                        ))}
                      </ul>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setOpen(false)}
        style={customStyles}
        contentLabel="MOMO"
        ariaHideApp={false}
      >
        <VoteMomoModal
          onClose={() => setOpen(false)}
          combo={combo}
          missId={missId}
          payUrl={payUrl}
        />
      </Modal>

      <div className="modal-container">
        <Modal
          isOpen={isOpenPrice}
          onRequestClose={() => setOpenPrice(false)}
          style={customStylesPriceModal}
          contentLabel="Quy đổi điểm bình chọn"
          ariaHideApp={false}
        >
          <PriceModal onClose={() => setOpenPrice(false)} />
        </Modal>
      </div>
    </div>
  );
};
// }

export default ContestantDetail;
