import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { INTRO_HOME } from "../../../config/contentHtml";
import { IoMdMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa";
import { MdLink } from "react-icons/md";
class SectionAbout extends Component {
  state = {
    intro: "",
  };

  async componentDidMount() {
    try {
      const INTRO = this.props.lang === "en" ? INTRO_HOME : INTRO_HOME;

      const intro = INTRO;

      this.setState({ intro });
    } catch (error) {
      console.log("xxx Get intro : ", error);
    }
  }

  // static getDerivedStateFromProps(props, state) {
  //   return { lang: props.lang };
  // }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.lang !== this.props.lang) {
      const INTRO = this.props.lang === "en" ? INTRO_HOME : INTRO_HOME;

      // const INTRO = this.props.lang === 'en' ? INTRO5_EN : INTRO5;

      const intro = INTRO;

      this.setState({ intro });
    }
  }

  render() {
    const { intro } = this.state;

    return (
      // <section className="section-about">
      //   <div className="about-img">
      //     <img src="images/about_img.jpg" alt="" data-aos="zoom-out-left" />
      //   </div>
      //   <div className="bs-container">
      //     <div className="bs-row row-md-15">
      //       <div className="bs-col md-50-15">
      //         <div className="module module-about" data-aos="zoom-out-right">
      //           <div className="module-header">
      //             <h2 className="title">
      //               <FormattedMessage id="nav.intro" />
      //             </h2>
      //           </div>
      //           <div className="module-content">
      //             <div className="desc" style={{ marginBottom: 5 }}>
      //               {ReactHtmlParser(intro)}
      //             </div>
      //             <Link className="about__link" to="/gioi-thieu">
      //               <FormattedMessage id="index.continue" />
      //             </Link>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </section>

      <section className="section-about">
        <div className="bs-container">
          <div className="bs-row row-md-15">
            <div className="bs-col col-sm-12 md-50-15">
              <div className="module module-about" data-aos="zoom-out-right">
                <div className="module-header">
                  <span className="id">01</span>
                  <h2 className="title">Giới thiệu Bvote</h2>
                </div>
                <div className="module-content">
                  <div className="desc" style={{ marginBottom: 5 }}>
                    {ReactHtmlParser(intro)}
                  </div>

                  <ul className="info-website">
                    <p className="title">
                      CÔNG TY CỔ PHẦN{" "}
                      <a
                        className="link"
                        href="https://bvote.com.vn/index.html"
                      >
                        BVOTE
                      </a>{" "}
                      VIỆT NAM
                    </p>
                    <li>
                      {/* <span>
                         <ImLocation2 className="icon"/> 
                         </span> */}
                      Tổ hợp văn phòng Sunsquare - Số 21 Lê Đức Thọ - Phường Mỹ
                      Đình 2 - Quận Nam Từ Liêm - Hà Nội.
                    </li>
                    <li>
                      <p>Bvote – Bình chọn nhanh nhạy- Tin cậy người dùng</p>
                    </li>
                    <li>
                      <span>
                        {" "}
                        <MdLink className="icon" />
                      </span>
                      Website: https://bvote.com.vn/
                    </li>
                    <li>
                      <span>
                        {" "}
                        <IoMdMail className="icon" />
                      </span>
                      Email: bvote@bvote.vn
                    </li>
                    <li>
                      <span>
                        <FaPhone className="icon" />
                      </span>
                      Hotline: +84 86 966 1866
                    </li>
                  </ul>

                  <Link className="about__link" to="/gioi-thieu">
                    <FormattedMessage id="index.continue" />
                    <img alt="arrow" src="/images/arrow-right.png" />
                  </Link>
                </div>
                <div className="about-img">
                  <div className="img">
                    {/* <img
                      src="images/introduce1.png"
                      alt=""
                      data-aos="zoom-out-left"
                    /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="bs-col col-sm-12 md-50-15">
              {/* <div class="about-img">
                <div class="img">
                  <img
                    src="images/introduce1.png"
                    alt=""
                    data-aos="zoom-out-left"
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default SectionAbout;
